<script setup lang="ts">
import googleCalendarLogo from '@/assets/svg/google-calendar-icon.svg';

const props = defineProps({
	isShow: {
		type: Boolean,
		default: false,
		required: true,
	},
});

defineEmits<{
	(e: 'onClose'): void
	(e: 'onAddGoogleCalendar'): void
	(e: 'onDownloadCalendar'): void
}>();
</script>

<template>
  <BaseDialog
    :is-show="props.isShow"
    @on-close="$emit('onClose')"
  >
    <template #header>
      <span class="text-lg text-semibold">Select option</span>
    </template>
    <template #content>
      <BaseButton
        variant="outlined"
        color="gray"
        @click="$emit('onAddGoogleCalendar')"
      >
        <template #leftIcon>
          <img :src="googleCalendarLogo">
        </template>
        Add to Google Calendar
      </BaseButton>
      <BaseButton
        variant="outlined"
        color="gray"
        @click="$emit('onDownloadCalendar')"
      >
        <template #leftIcon>
          <DownloadIcon />
        </template>
        Download iCalendar file (.ics)
      </BaseButton>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.dialog-confirm-title {
  color: colors-get(gray, 900);
}

.dialog-confirm-content {
  color: colors-get(gray, 600);
}

.btn-container {
  display: flex;
  gap: spacings-get(3);
  align-items: center;
  justify-content: space-between;

  @include media-query-max(mobile) {
    flex-direction: column-reverse;
  }
}
</style>
